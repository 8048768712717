<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-n2">
        <h4 style="font-weight: 500">Notifications</h4>
        </div>
        <hr  />
        <mdb-card class="z-depth-1">
            <mdb-card-body class="card-reversed">
                <form  @submit.prevent="updateNotif()" >
                    <mdb-row>
                        <mdb-col sm="12" md="12" v-if="$store.state.auth.company.setting.debt_actived === 1">
                            <dl>
                                <dt>
                                    <div>Destinataires de reçu de versement et de ventes à crédit </div>
                                </dt>
                                <dd>
                                    <mdb-col sm="12" md="8" class="pl-3">
                                        <p>Entrez vos adresses e-mail en les séparant par des points virgule</p>
                                        <mdb-input
                                            :disabled="!$gate.hasPermission(['edit::setting'])" 
                                            :validation="$store.state.setting.errors.bcc_debts ? true :false" 
                                            :invalidFeedback="$store.state.setting.errors.bcc_debts"
                                            v-model="formDebts.bcc_debts"
                                            outline label="Adresses email" size="md">
                                        </mdb-input>
                                    </mdb-col>
                                </dd>
                            </dl>
                        </mdb-col>
                        <mdb-col sm="12" md="12" v-if="$store.state.auth.company.setting.debt_actived === 1">
                            <dl>
                                <dt>
                                    <div>Alerte sur le niveau de consommation d'un client</div>
                                </dt>
                                <dd>
                                    <mdb-col sm="12" md="8" class="pl-3">
                                        <p>Entrez le taux (en %) auquel le client sera notifié</p>
                                        <mdb-input
                                            :validation="$store.state.setting.errors.rate_credit_plafond_alert ? true :false" 
                                            :invalidFeedback="$store.state.setting.errors.rate_credit_plafond_alert"
                                            v-model="formDebts.rate_credit_plafond_alert"
                                            :disabled="!$gate.hasPermission(['edit::setting'])" 
                                            outline label="Taux d'alerte (%)" type="number" size="md">
                                        </mdb-input>
                                    </mdb-col>
                                </dd>
                                <dd>
                                    <mdb-col sm="12" md="8" class="pl-3">
                                        
                                        <p>Entrez vos adresses e-mail qui recevront cette alerte sur le niveau de consommation du client en les séparant par des points virgule</p>
                                        <mdb-input
                                            :disabled="!$gate.hasPermission(['edit::setting'])" 
                                            :validation="$store.state.setting.errors.email_credit_plafond_alert ? true :false" 
                                            :invalidFeedback="$store.state.setting.errors.email_credit_plafond_alert"
                                            v-model="formDebts.email_credit_plafond_alert"
                                            outline label="Adresses email" size="md">
                                        </mdb-input>
                                    </mdb-col>
                                </dd>
                            </dl>
                        </mdb-col>
                        <mdb-col sm="12" class="d-flex justify-content-end">
                           <button v-if="$gate.hasPermission(['edit::setting'])" type="submit" :disabled="submitBtn" class="btn btn-primary btn-md" > 
                                <span v-if="!submitBtn">Sauvegarder</span>
                                <span v-if="submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-if="submitBtn" class="pl-2">Loading...</span>
                             </button>
                        </mdb-col>
                    </mdb-row>
                </form>
            </mdb-card-body>
        </mdb-card>
    </div>
</template>

<script>
import {
    mdbInput,
    mdbRow,mdbCol,
    mdbCard,mdbCardBody,
    // mdbSwitch,
} from 'mdbvue';
export default {
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - PARAMETRE NOTIFICATIONS',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        mdbInput,
        mdbRow,mdbCol,
        mdbCard,mdbCardBody,
        // mdbSwitch,
    },
    data(){
        return{
            submitBtn:false,
            formDebts:{
                id:this.$store.state.auth.company.setting.id,
                bcc_debts:this.$store.state.auth.company.setting.bcc_debts,
                rate_credit_plafond_alert:this.$store.state.auth.company.setting.rate_credit_plafond_alert,
                email_credit_plafond_alert:this.$store.state.auth.company.setting.email_credit_plafond_alert,
            }
        }
    },

    methods:{
        async updateNotif() {
            this.$nprogress.start()
            this.$store.commit('setting/SET_CLEAN')
            this.submitBtn = !this.submitBtn
			await this.$store.dispatch('setting/updateNotification', this.formDebts)
			.then((response) => {
                this.$nprogress.done()
                this.submitBtn = !this.submitBtn
                this.$notify({
                     
                    message: response.data.message,
                    type: 'success'
                })
                this.$store.dispatch('auth/attempt',localStorage.getItem('token'));
			})
			.catch((error) => {
                this.$nprogress.done()
                this.submitBtn = !this.submitBtn
                if (error.response.data.errors) {
                    // this.$store.commit('setting/SET_SWIFT', error.response.data.errors.swift)
                }
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },
    },
}
</script>